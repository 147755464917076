@tailwind base;
@tailwind components;
@tailwind utilities;


/* ======== Typography ======== */
@layer components {

    h1 {
        font-size: 2.25rem;
        line-height: 2.5rem;
        font-weight: 300;
    }

    h2 {
        font-size: 1.875rem;
        line-height: 2.25rem;
        font-weight: 300;
    }

    h3 {
        font-size: 1.5rem;
        line-height: 2rem;
        font-weight: 300;
    }

    h4 {
        font-size: 1.25rem;
        line-height: 1.75rem;
        font-weight: 300;
    }

    h5 {
        font-size: 1rem;
        line-height: 1.5rem;
        font-weight: 300;
    }

    h6 {
        font-size: 0.875rem;
        line-height: 1.25rem;
        font-weight: 300;
    }

    p {
        font-size: 1rem;
        line-height: 1.5rem;
    }

}


/* ======== Shadow colors ======== */
@layer components {

    /* Tailwind default shadow sizes as CSS variables */
    :root {
        --shadow-xs: 0 1px 1px;
        --shadow-sm: 0 1px 2px -1px;
        --shadow-md1: 0 4px 6px -1px;
        --shadow-md2: 0 2px 4px -2px;
        --shadow-lg1: 0 10px 15px -3px;
        --shadow-lg2: 0 4px 6px -4px;
        --shadow-xl1: 0 20px 25px -5px;
        --shadow-xl2: 0 10px 10px -5px;
        --shadow-2xl: 0 25px 50px -12px;
    }

    :root {
        /* Primary shadow color
            tailwind sky
            theme(colors.sky.500/20)
            rgba(56, 189, 248, 0.5)
        */
        --shadow-primary-shade: rgba(56, 189, 248, 0.5);

        /* Success shadow color
            tailwind green
            theme(colors.green.500/20)
            rgba(72, 187, 120, 0.5)
        */
        --shadow-success-shade: rgba(72, 187, 120, 0.5);

        /* Danger shadow color
            tailwind red
            theme(colors.red.500/20)
            rgba(244, 67, 54, 0.5)
        */
        --shadow-danger-shade: rgba(244, 67, 54, 0.5); /* theme(colors.red.500/50) */
    }

    /* Custom classes with Tailwind theme colors */
    /* The box-shadow format is: size color, size color, .... */

    /* Primary (Sky) */
    .shadow-xs-primary {
        box-shadow: var(--shadow-xs) var(--shadow-primary-shade), var(--shadow-xs) var(--shadow-primary-shade);
    }
    .shadow-sm-primary {
        box-shadow: var(--shadow-sm) var(--shadow-primary-shade), var(--shadow-sm) var(--shadow-primary-shade);
    }
    .shadow-md-primary {
        box-shadow: var(--shadow-md1) var(--shadow-primary-shade), var(--shadow-md2) var(--shadow-primary-shade);
    }
    .shadow-lg-primary {
        box-shadow: var(--shadow-lg1) var(--shadow-primary-shade), var(--shadow-lg2) var(--shadow-primary-shade);
    }
    .shadow-xl-primary {
        box-shadow: var(--shadow-xl1) var(--shadow-primary-shade), var(--shadow-xl2) var(--shadow-primary-shade);
    }
    .shadow-2xl-primary {
        box-shadow: var(--shadow-2xl) var(--shadow-primary-shade), var(--shadow-2xl) var(--shadow-primary-shade);
    }
    .shadow-primary{
        @apply shadow-md-primary;
    }

    /* Success (Green) */
    .shadow-xs-success {
        box-shadow: var(--shadow-xs) var(--shadow-success-shade), var(--shadow-xs) var(--shadow-success-shade);
    }
    .shadow-sm-success {
        box-shadow: var(--shadow-sm) var(--shadow-success-shade), var(--shadow-sm) var(--shadow-success-shade);
    }
    .shadow-md-success {
        box-shadow: var(--shadow-md1) var(--shadow-success-shade), var(--shadow-md2) var(--shadow-success-shade);
    }
    .shadow-lg-success {
        box-shadow: var(--shadow-lg1) var(--shadow-success-shade), var(--shadow-lg2) var(--shadow-success-shade);
    }
    .shadow-xl-success {
        box-shadow: var(--shadow-xl1) var(--shadow-success-shade), var(--shadow-xl2) var(--shadow-success-shade);
    }
    .shadow-2xl-success {
        box-shadow: var(--shadow-2xl) var(--shadow-success-shade), var(--shadow-2xl) var(--shadow-success-shade);
    }
    .shadow-success{
        @apply shadow-md-success;
    }

    /* Danger (Red) */
    .shadow-xs-danger {
        box-shadow: var(--shadow-xs) var(--shadow-danger-shade), var(--shadow-xs) var(--shadow-danger-shade);
    }
    .shadow-sm-danger {
        box-shadow: var(--shadow-sm) var(--shadow-danger-shade), var(--shadow-sm) var(--shadow-danger-shade);
    }
    .shadow-md-danger {
        box-shadow: var(--shadow-md1) var(--shadow-danger-shade), var(--shadow-md2) var(--shadow-danger-shade);
    }
    .shadow-lg-danger {
        box-shadow: var(--shadow-lg1) var(--shadow-danger-shade), var(--shadow-lg2) var(--shadow-danger-shade);
    }
    .shadow-xl-danger {
        box-shadow: var(--shadow-xl1) var(--shadow-danger-shade), var(--shadow-xl2) var(--shadow-danger-shade);
    }
    .shadow-2xl-danger {
        box-shadow: var(--shadow-2xl) var(--shadow-danger-shade), var(--shadow-2xl) var(--shadow-danger-shade);
    }
    .shadow-danger{
        @apply shadow-md-danger;
    }

}


/* ======== Buttons ======== */
@layer components {

    /* ==== Documentation ==== */

    /* == hover : mouse is over the element */
    /* == active: element is actively being clicked */
    /* == focus : element was clicked and now in focused state, or tabbed onto etc */

    /* Prefer atomized architecture, rather than hierarchy architecture */
    /* Avoid more then 2 levels of hierarchy -
        ✓ e.g btn -> btn-danger{ btn }
        X e.g btn-base -> btn-basic { btn-base } -> btn{ btn-basic } -> btn-danger{ btn }
        */

    /* Button design
        IDLE:
            - just show the bg color
            - no shadow, or border, outline or ring
            - rounded
        HOVER:
            - darken the bg color
            - show a shadow
        ACTiVE:
            - dark bg color
            - maintain shadow
            - show ring, that expands outwards
            - rounded to square
        FOCUS:
            - maintain square rounded
            - return bg to idle
            - ring fades out

    */

    /* == use outline - not border
        - Outline and border are the same CSS, however:
        - border affects the size of the element
        - outline will not affect the size of the element, and therefor wont affect the layout
    */

    /* ==== Classes ==== */

    /* The base button has all the classes of styled buttons, but all colors are clear */
    .btn-default{

        /* Text */
        @apply text-gray-800 font-light text-sm;

        /* Round */
        @apply rounded-lg hover:rounded-md;

        /* Transition */
        @apply transition-all ease-in-out duration-100;

        /* Shadow */
        @apply shadow-sm hover:shadow-md;

        /* Center items */
        @apply flex justify-center items-center;

        /* Gap between children */
        @apply gap-2;

        /* Width and height */
        @apply w-fit h-auto;

        /* Padding and Margin */
        @apply px-4 py-2 m-0;

        /* Border */
        @apply border border-transparent border-solid border-0;

        /* Outline */
        @apply outline outline-transparent outline-[1px];

        /* Ring */
        @apply ring ring-transparent ring-[1px] ring-offset-0;

        /* Background */
        @apply bg-transparent;

        /* Hover */
        @apply
            hover:bg-gray-100
            hover:outline-transparent
        ;

        /* Focus */
        @apply
            focus:outline-gray-200
        ;

        /* Active */
        @apply
            active:bg-gray-200
            active:rounded-none
            active:ring-offset-[3px] active:ring-gray-400
        ;

        /* Cursor */
        @apply hover:cursor-pointer;

    }

    /* Styled buttons simply override colors */
    .btn-primary {
        @apply btn-default;

        /* Background */
        @apply bg-sky-600 text-white;

        /* Hover */
        @apply
            hover:bg-sky-700
        ;

        /* Focus */
        @apply
            focus:outline-sky-800
        ;

        /* Active */
        @apply
            active:bg-sky-800
            active:ring-sky-600
        ;

    }
    .btn {
        @apply btn-primary;
    }

    .btn-success {
       @apply btn-default;

        /* Background */
        @apply bg-green-600 text-white;

        /* Hover */
        @apply
            hover:bg-green-700
        ;

        /* Focus */
        @apply
            focus:outline-green-800
        ;

        /* Active */
        @apply
            active:bg-green-800
            active:ring-green-600
        ;
    }
    .btn-danger {
       @apply btn-default;

        /* Background */
        @apply bg-red-600 text-white;

        /* Hover */
        @apply
            hover:bg-red-700
        ;

        /* Focus */
        @apply
            focus:outline-red-800
        ;

        /* Active */
        @apply
            active:bg-red-800
            active:ring-red-600
        ;
    }
    .btn-light {
       @apply btn-default;

        /* Background */
        @apply bg-white outline-gray-100;

        /* Hover */
        @apply
            hover:bg-gray-100
        ;

        /* Focus */
        @apply
            focus:outline-gray-200
        ;

        /* Active */
        @apply
            active:bg-gray-200
            active:ring-gray-200
        ;
    }
    .btn-disabled, button:disabled {
       @apply btn-default;

        /* Background */
        @apply bg-gray-200 pointer-events-none;

        /* Hover */
        @apply
            hover:bg-gray-200
        ;

        /* Focus */
        @apply
            focus:outline-gray-200
        ;

        /* Active */
        @apply
            active:bg-gray-200
            active:ring-transparent
        ;
    }

    /* Unset all styles that could be applied by the button styles */
    .btn-unset {
        all: unset;
    }
}


/* ======== Buttons - outline ======== */
@layer components {

    /* ==== Documentation ==== */

    /* === For full documentation, look above at "Buttons" component */

    /* == hover : mouse is over the element */
    /* == active: element is actively being clicked */
    /* == focus : element was clicked and now in focused state, or tabbed onto etc */


    /* ==== Classes ==== */

    /* The base button has all the classes of styled buttons, but all colors are clear */
    .btn-outline-default{

        /* Text */
        @apply text-gray-800 font-light text-sm;

        /* Round */
        @apply rounded-lg hover:rounded-md;

        /* Transition */
        @apply transition-all ease-in-out duration-100;

        /* Shadow */
        @apply shadow-sm hover:shadow-md;

        /* Center items */
        @apply flex justify-center items-center;

        /* Gap between children */
        @apply gap-2;

        /* Width and height */
        @apply w-fit h-auto;

        /* Padding and Margin */
        @apply px-4 py-2 m-0;

        /* Border */
        @apply border border-transparent border-solid border-0;

        /* Outline */
        @apply outline outline-gray-100 outline-[1px];

        /* Ring */
        @apply ring ring-transparent ring-[1px] ring-offset-0;

        /* Background */
        @apply bg-transparent;

        /* Hover */
        @apply
            hover:bg-transparent
            hover:outline-gray-100
        ;

        /* Focus */
        @apply
            focus:outline-gray-200
        ;

        /* Active */
        @apply
            active:bg-gray-200
            active:rounded-none
            active:ring-offset-[3px] active:ring-gray-400
        ;

        /* Cursor */
        @apply hover:cursor-pointer;

    }

    /* Styled buttons simply override colors */
    .btn-outline-primary {
        @apply btn-outline-default;

        /* Background */
        @apply outline-sky-600;

        /* Focus */
        @apply
            focus:outline-sky-800
        ;

        /* Hover */
        @apply
            hover:outline-sky-400
        ;

        /* Active */
        @apply
            active:bg-sky-800
            active:text-white
            active:ring-sky-600
        ;

    }
    .btn-outline {
        @apply btn-outline-primary;
    }

    .btn-outline-success {
       @apply btn-outline-default;

        /* Background */
        @apply outline-green-600;

        /* Focus */
        @apply
            focus:outline-green-800
        ;

        /* Hover */
        @apply
            hover:outline-green-400
        ;

        /* Active */
        @apply
            active:bg-green-800
            active:text-white
            active:ring-green-600
        ;

    }
    .btn-outline-danger {
       @apply btn-outline-default;

        /* Background */
        @apply outline-red-600;

        /* Focus */
        @apply
            focus:outline-red-800
        ;

        /* Hover */
        @apply
            hover:outline-red-400
        ;

        /* Active */
        @apply
            active:bg-red-800
            active:text-white
            active:ring-red-600
        ;

    }
    .btn-outline-light {
       @apply btn-outline-default;

        /* Background */
        @apply bg-transparent outline-gray-100 outline-gray-400;

        /* Focus */
        @apply
            focus:outline-gray-800
        ;

        /* Hover */
        @apply
            hover:outline-gray-400
        ;

        /* Active */
        @apply
            active:bg-gray-800
            active:text-white
            active:ring-gray-600
        ;
    }
    button:disabled[class*="btn-outline"] {
        @apply btn-outline-default;

        @apply outline-gray-200 bg-transparent pointer-events-none;
    }
}


/* ======== Buttons - raised ======== */
@layer components {

    /* ==== Documentation ==== */

    /* === For full documentation, look above at "Buttons" component */

    /* == hover : mouse is over the element */
    /* == active: element is actively being clicked */
    /* == focus : element was clicked and now in focused state, or tabbed onto etc */


    /* ==== Classes ==== */

    /* The base button has all the classes of styled buttons, but all colors are clear */
    .btn-raised-default{

        /* Text */
        @apply text-gray-800 font-light text-sm;

        /* Round */
        @apply rounded-lg hover:rounded-md;

        /* Transition */
        @apply transition-all ease-in-out duration-100;

        /* Shadow */
        @apply shadow-sm hover:shadow-md;

        /* Center items */
        @apply flex justify-center items-center;

        /* Gap between children */
        @apply gap-2;

        /* Width and height */
        @apply w-fit h-auto;

        /* Padding and Margin */
        @apply px-4 py-2 m-0;

        /* Border */
        @apply border border-transparent border-solid border-0;

        /* Outline */
        @apply outline outline-transparent outline-[1px];

        /* Ring */
        @apply ring ring-transparent ring-[1px] ring-offset-0;

        /* Background */
        @apply bg-white;

        /* Hover */
        @apply
            hover:outline-transparent
        ;

        /* Focus */
        @apply
            focus:outline-gray-200
        ;

        /* Active */
        @apply
            active:bg-gray-200
            active:rounded-none
            active:ring-offset-[3px] active:ring-gray-400
        ;

        /* Cursor */
        @apply hover:cursor-pointer;

    }

    /* Styled buttons simply override colors */
    .btn-raised-primary {
        @apply btn-raised-default;

        /* Background */
        @apply text-sky-600;

        /* Focus */
        @apply
            focus:outline-sky-800
        ;

        /* Active */
        @apply
            active:bg-sky-800
            active:outline-sky-600
            active:text-white
            active:ring-sky-600
        ;

    }
    .btn-raised {
        @apply btn-raised-primary;
    }

    .btn-raised-success {
       @apply btn-raised-default;

        /* Background */
        @apply text-green-600;

        /* Focus */
        @apply
            focus:outline-green-800
        ;

        /* Active */
        @apply
            active:bg-green-800
            active:outline-green-600
            active:text-white
            active:ring-green-600
        ;

    }
    .btn-raised-danger {
       @apply btn-raised-default;

        /* Background */
        @apply text-red-600;

        /* Focus */
        @apply
            focus:outline-red-800
        ;

        /* Active */
        @apply
            active:bg-red-800
            active:outline-red-600
            active:text-white
            active:ring-red-600
        ;

    }
    .btn-raised-light {
       @apply btn-raised-default;

        /* Background */
        @apply bg-white;

        /* Focus */
        @apply
            focus:outline-gray-800
        ;

        /* Active */
        @apply
            active:bg-gray-800
            active:outline-gray-400
            active:text-white
            active:ring-gray-600
        ;
    }

}


/* ======== Buttons - icon ======== */
@layer components {

    /* ==== Documentation ==== */

    /* === For full documentation, look above at "Buttons" component */

    /* == hover : mouse is over the element */
    /* == active: element is actively being clicked */
    /* == focus : element was clicked and now in focused state, or tabbed onto etc */


    /* ==== Classes ==== */

    /* The base button has all the classes of styled buttons, but all colors are clear */
    .btn-icon-default{

        /* Text */
        @apply text-gray-800 font-light text-sm;

        /* Round */
        @apply rounded-full;

        /* Transition */
        @apply transition-all ease-in-out duration-100;

        /* Shadow */
        @apply shadow-none;

        /* Center items */
        @apply flex justify-center items-center;

        /* Gap between children */
        @apply gap-2;

        /* Width and height */
        @apply w-fit h-auto;

        /* Padding and Margin */
        @apply p-1 m-0;

        /* Border */
        @apply border border-transparent border-solid border-0;

        /* Outline */
        @apply outline outline-transparent outline-[1px];

        /* Ring */
        @apply ring ring-transparent ring-[1px] ring-offset-0;

        /* Background */
        @apply bg-transparent;

        /* Hover */
        @apply
            hover:outline-transparent
            hover:shadow-md
        ;

        /* Focus */
        @apply
            focus:outline-gray-200
        ;

        /* Active */
        @apply
            active:bg-gray-200
            active:rounded-md
            active:shadow-xl
            active:ring-offset-[3px] active:ring-gray-400
        ;

        /* Cursor */
        @apply hover:cursor-pointer;

    }

    /* Styled buttons simply override colors */
    .btn-icon-primary {
        @apply btn-icon-default;

        /* Background */
        @apply text-sky-600;

        /* Hover */
        @apply
            hover:shadow-md-primary
        ;

        /* Focus */
        @apply
            focus:outline-sky-800
        ;

        /* Active */
        @apply
            active:bg-sky-800
            active:outline-sky-600
            active:text-white
            active:ring-sky-600
        ;

    }
    .btn-icon {
        @apply btn-icon-primary;
    }

    .btn-icon-success {
       @apply btn-icon-default;

        /* Background */
        @apply text-green-600;

        /* Hover */
        @apply
            hover:shadow-md-success
        ;

        /* Focus */
        @apply
            focus:outline-green-800
        ;

        /* Active */
        @apply
            active:bg-green-800
            active:outline-green-600
            active:text-white
            active:ring-green-600
        ;

    }
    .btn-icon-danger {
       @apply btn-icon-default;

        /* Background */
        @apply text-red-600;

        /* Hover */
        @apply
            hover:shadow-md-danger
        ;

        /* Focus */
        @apply
            focus:outline-red-800
        ;

        /* Active */
        @apply
            active:bg-red-800
            active:outline-red-600
            active:text-white
            active:ring-red-600
        ;

    }
    .btn-icon-light {
       @apply btn-icon-default;

        /* Focus */
        @apply
            focus:outline-gray-800
        ;

        /* Active */
        @apply
            active:bg-gray-800
            active:outline-gray-400
            active:text-white
            active:ring-gray-600
        ;
    }
    button:disabled[class*="btn-icon"] {
        @apply btn-icon-default;

        @apply pointer-events-none text-gray-400;
    }

}


/* ======== Inputs ======== */
@layer components {

    /* ==== Documentation ==== */

    /* == hover : mouse is over the element */
    /* == active: element is actively being clicked */
    /* == focus : element was clicked and now in focused state, or tabbed onto etc */


    /* ==== Classes ==== */

    .input, input {

        @apply focus:outline-0 ; /* override default browser behavior */

        /* Text */
        @apply text-gray-800 font-light text-sm;

        /* Round */
        @apply rounded-lg hover:rounded-md;

        /* Transition */
        @apply transition-all ease-in-out duration-100;

        /* Shadow */
        @apply shadow-sm hover:shadow-md focus:shadow-inner;

        /* Center items */
        @apply flex justify-center items-center;

        /* Gap between children */
        @apply gap-2;

        /* Width and height */
        @apply w-full h-auto;

        /* Padding and Margin */
        @apply px-4 py-2 m-0;

        /* Border */
        /*@apply border border-transparent border-solid border-0;*/
        @apply border border-[1px] border-gray-300 border-gray-200 hover:border-sky-300 focus:border-sky-300;

        /* Outline */
        @apply outline outline-gray-100 outline-[1px];

        /* Ring */
        /*@apply ring ring-transparent ring-[1px] ring-offset-0;*/
        @apply ring-0 focus:ring-[1px] focus:ring-offset-[2px] focus:ring-sky-200;

        /* Background */
        @apply bg-white;

        /* Cursor */
        @apply hover:cursor-text;

    }

    /* ===== TEXTAREA ===== */
    .textarea, textarea {
        @apply input;
        @apply block w-full; /* sizing */
    }


    /* ===== CHECKBOX ===== */
    .checkbox {
        @apply transition-all ease-in-out duration-200;
        @apply sm:text-sm;  /* text */
        @apply w-5 h-5; /* sizing */
        @apply rounded-md border-2 border-gray-300; /* styling */
        @apply hover:border-sky-300; /* hover */
        @apply focus:border-sky-300 focus:ring-1 focus:ring-sky-600 focus:ring-offset-2 focus:outline-0; /* misc */  /* focus */
    }

    /* ===== SELECT & OPTION ===== */
    .select, select {
        @apply input;
        @apply bg-white; /* idle */
        @apply px-2 py-3 pr-8 m-1 w-fit;  /* sizing */
        @apply hover:cursor-pointer;  /* hover */
    }
    select > option {
        @apply text-black;
    }



    /* TODO move to tables layer */
    .table-filter-search  {
        /* Column specific filter input */
        @apply input flex justify-center w-full min-w-[3.2rem] max-w-[95%] py-1 px-1 my-4 mx-0;
        @apply font-normal text-gray-400 text-xs shadow-none outline outline-1 outline-slate-200 border-0;
    }

}


/* ======== Tables ======== */
@layer components {


}

/* ======== Tooltips ======== */
/* ======== Misc ======== */




















