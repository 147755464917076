@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  overflow: hidden;
}

/* TODO replace usages with the new Tooltip component */
.tooltip {
    visibility: hidden;
    width: 105px;
    background-color: #585858;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;

    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    margin-top: 60px;
    margin-left: -40px;
}

.tooltip-container:hover .tooltip {
    visibility: visible;
}


.min-h-50px {
    min-height: 50px;
}

.hidden {
    display: none;
}





/*Scroll bars*/
.scrollbar
{
	float: left;
	height: 300px;
	width: 65px;
	background: #d7d7d7;
	overflow-y: scroll;
	margin-bottom: 25px;
}
::-webkit-scrollbar-track
{
	border-radius: 12px;
	background-color: #F5F5F5;
}
::-webkit-scrollbar
{
	width: 12px;
	background-color: transparent;
}
::-webkit-scrollbar-thumb
{
	border-radius: 12px;
	background-color: #d5d5d5;
}




@media only screen and (max-width: 1800px) {
    /* Due to some browsers behaving unexpectedly with zoom, only chrome is supported for this feature */
    .platform-zoom {
        /* Chrome */
        zoom: 0.8;
    }
    .h-screen-zoomed {
        height: 125vh !important;
    }
    .w-screen-zoomed {
        width: 125vw !important;
    }
}






/** ==== Compound Tailwind classes ==== **/

/** ==== Outline and Border act the same, but both can be used - Border affects sizing and can do individual sides(t,b,l,r) and round(radius) can be independent of the element - Outline can offset  **/
/** ==== Outline and Ring are the same thing - Outline is simple, Ring is highly customizable - you can only use 1 **/

@tailwind base;
@tailwind components;
@tailwind utilities;
@layer components {


    /* == hover : mouse is over the element */
    /* == active: element is actively being clicked */
    /* == focus : element was clicked and now in focused state, or tabbed onto etc */

    /* TODO change this deeply hierarchy architecture, to more atomized architecture */
    /* Avoid more then 2 levels of hierarchy - e.g btn -> btn-danger{ btn }*/

    .base-text {
        @apply text-base text-gray-800 font-light text-sm;
    }


    /* - - - - - - - - - - - - */

    .base {
        @apply text-sm font-light text-gray-800; /* text */
    }
    .base-round { @apply rounded-md hover:rounded-md focus:rounded-none; }
    .base-transition { @apply transition-all ease-in-out duration-100 ; }
    .base-shadow { @apply shadow-sm hover:shadow-md; }

    .outline-base { @apply outline outline-[1px]; }
    .outline-primary { @apply outline-base outline-gray-300 hover:outline-sky-300 focus:outline-sky-300; /* color */ }
    .outline-light { @apply outline-base outline-gray-300 hover:outline-stone-300 focus:outline-stone-300; /* color */ }
    .outline-success { @apply outline-base outline-green-300 hover:outline-green-300 focus:outline-green-300; /* color */ }
    .outline-danger { @apply outline-base outline-red-300 hover:outline-red-300 focus:outline-red-300; /* color */ }

    .border-base { @apply border border-[1px] border-gray-300; }
    .border-primary { @apply border-base border-gray-200 hover:border-sky-300 focus:border-sky-300; /* color */ }
    .border-light { @apply border-base border-gray-200 hover:border-stone-300 focus:border-stone-300; /* color */ }
    .border-success { @apply border-base border-green-600 hover:border-green-300 focus:border-green-300; /* color */ }
    .border-danger { @apply border-base border-red-300 hover:border-red-300 focus:border-red-300; /* color */ }

    .ring-base { @apply ring-0 focus:ring-[1px] ring-transparent focus:ring-offset-[2px]; }
    .ring-primary { @apply ring-base focus:ring-sky-200; /* color */ }
    .ring-light { @apply ring-base focus:ring-stone-200; /* color */ }
    .ring-success { @apply ring-base focus:ring-green-200; /* color */ }
    .ring-danger { @apply ring-base focus:ring-red-200; /* color */ }


    /* ===== btn - primary by default ===== */
    .btn-base {
        @apply base base-round base-shadow base-transition;
        @apply mx-2 px-4 my-1 py-3 h-auto w-fit items-center inline-flex;  /* sizing */
        @apply hover:cursor-pointer;  /* styling */
    }

    /*.btn {*/
    /*    @apply btn-base ring-primary border-primary;*/
    /*    @apply bg-sky-600 text-white;  !* styling *!*/
    /*    @apply hover:bg-sky-700 focus:bg-sky-800;  !* hover *!*/
    /*    !* === the btn class is styled as a primary button *!*/
    /*}*/
    /*.btn-primary {*/
    /*    @apply btn;*/
    /*    !* === the btn class is styled as a primary button *!*/
    /*}*/

    /*.btn-success {*/
    /*    @apply btn-base ring-success border-success;*/
    /*    @apply bg-green-600 text-white; !* idle *!*/
    /*    @apply hover:bg-green-500; !* hover *!*/
    /*    @apply focus:bg-green-800; !* focus *!*/
    /*}*/

    /*.btn-danger {*/
    /*    @apply btn-base ring-danger border-danger;*/
    /*    @apply bg-red-500 text-white; !* idle *!*/
    /*    @apply hover:bg-red-600; !* hover *!*/
    /*    @apply focus:bg-red-800; !* focus *!*/
    /*}*/

    /*.btn-light {*/
    /*    @apply btn-base ring-light border-light;*/
    /*    @apply bg-white text-gray-800 ; !* idle *!*/
    /*    @apply hover:bg-stone-200; !* hover *!*/
    /*    @apply focus:bg-stone-400; !* focus *!*/
    /*}*/

    /*.btn-outline {*/
    /*    @apply btn-base ring-primary border-primary;*/
    /*    @apply text-sky-800 hover:text-white focus:text-white; !* text *!*/
    /*    @apply bg-white;  !* idle *!*/
    /*    @apply hover:bg-sky-700;  !* hover *!*/
    /*    @apply focus:bg-sky-700;  !* focus *!*/
    /*}*/

    /*.btn-outline-primary {*/
    /*    @apply btn-outline;*/
    /*    !* === the btn class is styled as a primary button, no need to repeat the style here *!*/
    /*}*/

    /*.btn-outline-success {*/
    /*    @apply btn-base ring-success border-success;*/
    /*    @apply bg-white text-green-700 hover:text-white focus:text-white; !* idle *!*/
    /*    @apply hover:bg-green-700; !* hover *!*/
    /*    @apply focus:bg-green-800; !* focus *!*/
    /*}*/

    /*.btn-outline-danger {*/
    /*    @apply btn-base ring-danger border-danger;*/
    /*    @apply bg-white text-red-800 hover:text-white focus:text-white; !* idle *!*/
    /*    @apply hover:bg-red-700; !* hover *!*/
    /*    @apply focus:bg-red-800; !* focus *!*/
    /*}*/

    /*.btn-outline-light {*/
    /*    @apply btn-base ring-light border-light;*/
    /*    @apply bg-white; !* idle *!*/
    /*    @apply hover:bg-stone-200; !* hover *!*/
    /*    @apply focus:bg-stone-300; !* focus *!*/

    /*    @apply text-gray-600 border border-gray-300; !* misc *!*/
    /*}*/

    /*.btn-outline:disabled {*/
    /*    @apply bg-gray-100; !* idle *!*/
    /*    @apply hover:bg-stone-200; !* hover *!*/
    /*    @apply focus:ring-stone-300 focus:bg-stone-200; !* focus *!*/
    /*    @apply text-gray-600; !* misc *!*/
    /*}*/

    /*.btn-icon {*/
    /*    @apply text-gray-800 rounded-lg;*/
    /*    @apply p-1; !* sizing *!*/
    /*    @apply bg-transparent border-none;*/
    /*    @apply ring-0 ring-transparent;*/
    /*    @apply transition-all shadow-sm;*/
    /*    @apply hover:ring-1 hover:ring-sky-200 hover:shadow-lg; !* hover *!*/
    /*    @apply focus:ring-1 focus:ring-sky-300; !* focus *!*/
    /*    @apply active:ring-1 active:ring-sky-600; !* active *!*/

    /*}*/

    /*.btn-disabled {*/
    /*    @apply btn-base;*/
    /*    @apply bg-gray-100; !* idle *!*/
    /*    @apply hover:bg-gray-200 hover:shadow-sm; !* hover *!*/
    /*    @apply focus:ring-stone-300 focus:bg-stone-200; !* focus *!*/

    /*    @apply text-gray-600; !* misc *!*/
    /*}*/
    /*.btn:disabled { @apply btn-disabled; !* type disabled *! }*/

    /* TODO refactor this out/remove this */
    /*.btn-error {*/
    /*    @apply btn-base;*/
    /*    @apply bg-white; !* idle *!*/
    /*    @apply hover:bg-stone-100; !* hover *!*/
    /*    @apply focus:ring-stone-600 focus:bg-stone-300; !* focus *!*/

    /*    @apply text-gray-600 border border-purple-400; !* misc *!*/
    /*}*/
    /*!* TODO refactor this out/remove this *!*/
    /*.btn-outline-error {*/
    /*    @apply btn-outline;*/
    /*    @apply text-purple-900 bg-white; !* idle *!*/
    /*    @apply hover:text-black hover:bg-stone-100; !* hover *!*/
    /*    @apply focus:ring-stone-600 focus:bg-stone-300; !* focus *!*/

    /*    @apply border border-purple-400; !* misc *!*/
    /*}*/

    /*.btn-clear {*/
    /*    @apply btn-base;*/
    /*    @apply p-2;*/
    /*    @apply bg-transparent hover:bg-white;*/
    /*    @apply transform hover:scale-110; !* hover *!*/
    /*    @apply border-none outline-none; !* idle *!*/
    /*    @apply shadow-none hover:shadow-lg;*/
    /*}*/



    /* TODO replace usages with the new Tooltip component */
    /* ===== improved tooltip ===== */
    .tailwind-tooltip {
        @apply invisible opacity-0 m-3 pointer-events-none;
        @apply absolute z-[100] overflow-visible;
        @apply -top-[100%] -left-1/3;
        @apply transition-all ease-in-out duration-500;
        @apply text-sm text-white text-center;  /* text */
        @apply w-max max-w-[20rem] px-3 py-1; /* sizing */
        @apply bg-gray-700 rounded-md border-2 border-gray-300; /* styling */
        @apply hover:border-sky-300; /* hover */
        @apply focus:border-sky-300 focus:ring-1 focus:ring-sky-600 focus:ring-offset-2 focus:outline-0; /* misc */  /* focus */
    }
    .tailwind-tooltip-container {
        @apply relative;
        @apply !overflow-visible;
    }
    .tailwind-tooltip-container:hover .tailwind-tooltip { @apply visible opacity-100; }

    .tooltip_underline {
        @apply border-b-[1px] border-gray-300 border-dashed;
    }

    /* ===== DIVIDER - horizontal rule ===== */
    hr {
        @apply w-full w-full h-[1px] bg-gray-200 rounded-lg;
    }

    hr:last-child {
        @apply hidden; /* Hide HR if it's the last element */
    }


    /* ===== Global styling ===== */

    .body-background {
        @apply bg-slate-100;
    }
    .navbar-background {
        @apply bg-slate-800;
    }
    .page-header-bar {
        @apply flex items-center justify-between my-3 py-4 leading-6 font-medium text-gray-900 border-gray-200;
    }

    .body{
        @apply px-8;
    }

    .widget{
        @apply bg-white rounded-lg shadow-md p-4 m-2;
    }

    .manual-item-tag {
        @apply inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-blue-500 text-white;
    }

    .modal-header {
        @apply bg-slate-800 text-white;
    }


    /* ===== Tables ===== */

    table {
        @apply w-full mt-4;
    }

    thead, .table-header {
        /* <thead> */
    }

    .thead-dark {
        @apply bg-slate-100 border-b-0;
    }

    thead > tr, .table-header-row {
        /* <thead> -> <tr> */
        @apply text-left;
        @apply text-gray-700 bg-white h-[4rem] border border-0 border-b-2 border-slate-300;
    }

    thead > tr > th:first-child  {
        /* The first column of the header row */
        @apply pl-4;
    }
    thead > tr > th:last-child  {
        /* The last column of the header row */
        @apply pr-4;
    }

    tbody > tr, .table-body-row {
        /* <tbody> -> <tr> */
        @apply text-left;
    }

    th, .table-header-cell {
        /* <th> */
        @apply font-light hover:font-semibold transition-all;
    }

    tr, .table-row {
        /* <tr> */
        @apply text-center bg-white transition-all hover:bg-slate-100 h-[5.5rem];
    }

    td, .table-cell {
        /* <td> */
        @apply text-sm text-gray-800 border-0 border-b border-gray-200;
    }

    .divider {
        @apply w-full border-b-[1px] border-gray-200 my-4;
    }


    /* ===== HEADLESS UI ===== */

    /* === Radio === */
    .radio-container {
        /*@apply p-2;*/
    }
    .radio-option {
        /*@apply btn-outline;*/
        @apply cursor-pointer px-5 py-3 rounded-lg transition-all duration-500 ease-in-out shadow-sm;
        @apply bg-white text-gray-800 border-2 border-gray-200;
        @apply hover:bg-sky-100 hover:text-black ;
    }
    .radio-option-selected {
        /*@apply btn;*/
        @apply bg-sky-600 px-6 py-4 text-white hover:text-white font-medium border-transparent;
    }

    /* === Select === */
    .select-container {
        @apply w-full;
    }
    .select-option {
        @apply cursor-pointer px-6 py-4 rounded-lg transition-all duration-150 ease-in-out shadow-sm;
        @apply bg-white text-gray-800 border-2 border-gray-200;
        @apply hover:bg-sky-100 hover:text-black ;
    }
    .select-option-selected {
        @apply bg-sky-500 text-white font-medium border-transparent;
    }


    /* ===== Once off classes ===== */

    .divider_CRUD {
        @apply h-[1px] bg-gray-100 my-2 w-full;
    }

    .page-header-title {
        @apply text-4xl ml-8 font-light text-gray-700;
    }

}


/** ==== SCROLL BAR ==== **/

/* Change the scrollbar track and thumb colors */
::-webkit-scrollbar {
    width: 8px; /* Width of the scrollbar */
}
::-webkit-scrollbar-track {
    background-color: rgba(241, 241, 241, 0); /* Background of the scrollbar track */
}
::-webkit-scrollbar-thumb {
    background-color: #1e293b; /* Color of the scrollbar thumb */
    border-radius: 0rem;
}
/* For Firefox */
.scrollbar-custom {
    scrollbar-width: thin; /* Thin scrollbar */
    scrollbar-color: #5d6c83 rgba(241, 241, 241, 0); /* thumb color track color */
}


/** ==== HIDE SCROLL BAR ==== **/

/* For Webkit-based browsers (Chrome, Safari and Opera) */
.scrollbar-hide::-webkit-scrollbar {
    display: none;
}
/* For IE, Edge and Firefox */
.scrollbar-hide {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

/* For Webkit-based browsers (Chrome, Safari and Opera) */
.scrollbar-hide-bottom::-webkit-scrollbar:horizontal {
    display: none;
}
/* For IE, Edge and Firefox */
.scrollbar-hide-bottom {
    overflow-x: hidden;
}


/** ==== SLIDER (SnappyClaims modal) ==== **/

/* The slider itself */
.slider {
  -webkit-appearance: none;  /* Override default CSS styles */
  appearance: none;
  width: 100%; /* Full-width */
  height: 25px; /* Specified height */
  background: #f5f5f5; /* Grey background */
  outline: none; /* Remove outline */
  opacity: 0.7; /* Set transparency (for mouse-over effects on hover) */
  -webkit-transition: .2s; /* 0.2 seconds transition on hover */
  transition: opacity .2s;
}

/* Mouse-over effects */
.slider:hover {
  opacity: 1; /* Fully shown on mouse-over */
}

/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
.slider::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  width: 25px; /* Set a specific slider handle width */
  height: 25px; /* Slider handle height */
  background: rgba(167, 167, 167, 0.9); /* Green background */
  cursor: pointer; /* Cursor on hover */
}

.slider::-moz-range-thumb {
  width: 25px; /* Set a specific slider handle width */
  height: 25px; /* Slider handle height */
  background: rgba(167, 167, 167, 0.9); /* Green background */
  cursor: pointer; /* Cursor on hover */
}












